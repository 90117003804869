@import "~antd/dist/antd.css";
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.form-fields-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
}

.switch-button {
  margin-left: 20px;
}

#switch-user {
  align-self: flex-start;
  margin-left: 0;
}

.upload-wrapper {
  margin-bottom: 20px;
}

.icon-button {
  border: 0;
  background: transparent;
  box-shadow: none;
}

.icon-button-restore {
  color: #fff;
  font: rgb(45, 182, 27);
  background: rgb(45, 182, 27);
}

.icon-button:hover {
  background: transparent;
}

.icon-button:focus {
  background: transparent;
}

.actions-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
}

.ant-descriptions-item-label {
  font-weight: 700;
  width: 25%;
}

.ticket-draw-container .ant-descriptions-item-label {
  font-weight: 700;
  width: 35%;
}

.delete-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.delete-content p {
  font-size: 18px;
  margin-left: 20px;
  margin-bottom: 0;
  padding: 0;
}

.error-message {
  font-size: 12px;
}

.ant-form-item {
  margin-bottom: 36px;
}

.ticket-types-actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-in-wrapper {
  padding: 40px;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
}

.current-page {
  margin: 0 4px;
  min-width: 25px;
  max-width: fit-content;
  text-align: center;
  border: 1px solid #3390ff;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.spin-icon {
  font-size: 34px;
}

.enable-switch {
  width: 100%;
}

.statistical-wrapper > .ant-card-body {
  padding: 0;
}

.statistical-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.statistical-grid {
  width: 50%;
}

.statistical-full {
  width: 100%;
}

.statistical-wrapper .statistical-container .ant-card-head {
  text-align: center;
}

.statistical-wrapper .general-container .ant-card-head {
  text-align: center;
}

.statistical-wrapper .statistical-footer {
  padding: 24px 24px 0;
}

.duplicate-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.match-wrapper .form-fields-wrapper {
  margin-bottom: 0;
}

.match-wrapper .switch-button {
  margin-left: 50px;
}

.settings-sync-Odoo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
